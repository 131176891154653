import Typography from '@mui/material/Typography';
import Divider from 'atoms/divider';
import { PortfolioWrapper, PortfolioCard } from './portfolio.styled';
import { PORTFOLIO_OBJECT } from '../constant';

const Portfolio = () => {
  return (
    <PortfolioWrapper>
      <div className="divider-section">
        <Divider className="divider"></Divider>
        <Typography variant="body2" className="divider-section__title">
          {'And that’s not all...'.toUpperCase()}
        </Typography>
        <Divider className="divider"></Divider>
      </div>
      <div className="portfolio__banner">
        {PORTFOLIO_OBJECT.map((item, index) => (
          <PortfolioCard index={index} key={index}>
            <div className="icon">{item.ICON}</div>
            <Typography variant="h3" className="portfolio__title">
              {item.TITLE}
            </Typography>
            <Typography variant="body1" className="portfolio__description">
              {item.DESCRIPTION}
            </Typography>
          </PortfolioCard>
        ))}
      </div>
    </PortfolioWrapper>
  );
};

export default Portfolio;
