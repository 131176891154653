import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import React from 'react';
import { Field, Form } from 'react-final-form';
import isoLogo from '/public/kb-net/images/footer/iso.svg';
import registeredIcon from '/public/kb-net/images/footer/registeredIcon.svg';
import Image from 'features/core/Image';

// import { useMediaQuery } from '@mui/material';
import { svgIcons } from 'assets/svg-icons';
import Button from 'atoms/button';
import Divider from 'atoms/divider';
import TextField from 'atoms/text-field';
import { INDIAN_PHONE_LENGTH, INDIA_COUNTRY_CODE } from 'constants/phone';
// import theme from 'features/core/theme';
import Login from 'features/onboarding';
import { BillingAccountingFeatureList } from '../billing-accounting-feature-list';
import {
  APP_DOWNLOAD_LINK_LIST_FOR_FOOTER,
  TALLY_DISCLAIMER
} from '../constant';
import { FeatureCheckWrapper } from '../styled';
import { useFooterActions } from './actions';
import { DEMO_ACCOUNT, FOOTER_LINKS, PAGE_FOOTER } from './constant';
import { FieldWrapper, FooterCard, FooterWrapper } from './footer.styled';
import LiveDemoInfo from '../common/demo-account/LiveDemoInfo';
import { checkForMobileDevice } from 'utils/browserUtils';

const Footer = () => {
  const isMobile = checkForMobileDevice(); //useMediaQuery(theme.breakpoints.down('md'));
  const {
    phoneNumber,
    setPhoneNumber,
    loginModal,
    source,
    handleLoginModal,
    updateSource
  } = useFooterActions();

  const onSubmit = ({ phoneNumber }: { phoneNumber: string }) => {
    setPhoneNumber(phoneNumber);
    updateSource('getStarted');
    //TODO: redirect to khatabook.com
    handleLoginModal?.({ open: true, phoneNumber });
  };

  const handleTextFieldInputChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    props: any
  ) => {
    const nonNumReg = /[^0-9]/g;
    e.target.value = e.target.value.replace(nonNumReg, '');
    props.input.onChange(e); //final-form's onChange
  };

  return (
    <FooterWrapper>
      {loginModal && (
        <Login
          source={source}
          phoneNumber={phoneNumber}
          handleLoginModal={handleLoginModal}
        />
      )}

      <FooterCard>
        <div className="header-box">
          <div className="header-content">
            <Typography
              variant="h2"
              sx={{ marginBottom: '2rem', fontFamily: 'Poppins, cursive' }}
              className="header-title"
            >
              {PAGE_FOOTER().HEADER}
            </Typography>

            <Form
              onSubmit={onSubmit}
              render={({ handleSubmit }) => (
                <form className="phone-number-form" onSubmit={handleSubmit}>
                  <FieldWrapper>
                    <Field className="phone-number" name="phoneNumber">
                      {props => {
                        return (
                          <TextField
                            maxLength={INDIAN_PHONE_LENGTH}
                            className={props.className}
                            placeholder="Enter your Phone Number"
                            {...props.input}
                            onChange={(
                              e: React.ChangeEvent<HTMLInputElement>
                            ) => handleTextFieldInputChange(e, props)}
                            startAdornment={
                              <div
                                style={{
                                  marginRight: '0.5rem',
                                  borderRight: '1px solid #D6D6D6',
                                  display: 'flex',
                                  alignItems: 'center',
                                  padding: 16
                                }}
                              >
                                <Typography
                                  style={{
                                    marginRight: '0.5rem',
                                    fontSize: 18
                                  }}
                                >
                                  {INDIA_COUNTRY_CODE}
                                </Typography>
                              </div>
                            }
                          />
                        );
                      }}
                    </Field>
                  </FieldWrapper>

                  <Button
                    className="startTrial_btn"
                    variant="contained"
                    type="submit"
                  >
                    Get Started
                  </Button>
                </form>
              )}
            />
            {isMobile && (
              <div className="apps-section">
                {APP_DOWNLOAD_LINK_LIST_FOR_FOOTER.map(
                  ({ image, link }, index) => (
                    <div className="appImageBadge" key={index}>
                      <Link
                        key={index}
                        href={link}
                        color="inherit"
                        underline="none"
                        target="_blank"
                        rel="noreferrer"
                        className=" app-link"
                      >
                        {image}
                      </Link>
                    </div>
                  )
                )}
              </div>
            )}
            <FeatureCheckWrapper>
              <BillingAccountingFeatureList
                showCheckIcon={true}
                borderMobileRight
              />
            </FeatureCheckWrapper>
          </div>
        </div>
        <img
          className="phone_mockup"
          src="https://khatabook-assets.s3.ap-south-1.amazonaws.com/kb-pwa/kb-mockuo-d7ed5f5426.webp"
          alt="mobilePhone-view"
        ></img>
        <div className="scanner-section">
          <img
            className="scanner-image"
            src="https://khatabook-assets.s3.ap-south-1.amazonaws.com/kb-pwa/qr-code-ee8e53172b.webp"
            alt="khatabook_scanner"
          />
          <Typography
            style={{
              textAlign: 'center',
              marginBottom: '1rem',
              fontWeight: '600'
            }}
          >
            {PAGE_FOOTER().SCAN}
          </Typography>
          {APP_DOWNLOAD_LINK_LIST_FOR_FOOTER.map(({ image, link }, index) => (
            <div className="appImageBadge" key={index}>
              <Link
                key={index}
                href={link}
                color="inherit"
                underline="none"
                target="_blank"
                rel="noreferrer"
                className="app-link"
              >
                {image}
              </Link>
            </div>
          ))}
        </div>
      </FooterCard>

      <div className={'demo-account-widget'}>
        <LiveDemoInfo
          source={'footer'}
          title={DEMO_ACCOUNT().TITLE}
          description={DEMO_ACCOUNT().DESCRIPTION}
          ctaText={DEMO_ACCOUNT().CTA_TITLE}
        />
      </div>

      <div className="tally-disclaimer">
        <div>{TALLY_DISCLAIMER}</div>
      </div>

      <div className="footer">
        <div className="footer__content">
          <div className="footer__content__title">
            <div className="footer__content__title__logo">
              {svgIcons.khatabook_main}
            </div>
            <Typography variant="body1">{PAGE_FOOTER().CONTENT}</Typography>
            <div className="footer__content__title__svg">
              <Link
                href="https://www.youtube.com/@Khatabook_official"
                color="inherit"
                underline="none"
                target="_blank"
                rel="noreferrer"
              >
                {svgIcons.youtube}
              </Link>
              <Link
                href="https://www.instagram.com/khata.book/?hl=en"
                color="inherit"
                underline="none"
                target="_blank"
                rel="noreferrer"
              >
                {svgIcons.instagram}
              </Link>
              <Link
                href="https://twitter.com/Khatabook"
                color="inherit"
                underline="none"
                target="_blank"
                rel="noreferrer"
              >
                {svgIcons.twitter}
              </Link>
              <Link
                href="https://www.facebook.com/khatabook"
                color="inherit"
                underline="none"
                target="_blank"
                rel="noreferrer"
              >
                {svgIcons.facebook}
              </Link>
              <Link
                href="https://www.linkedin.com/company/khatabook"
                color="inherit"
                underline="none"
                target="_blank"
                rel="noreferrer"
              >
                {svgIcons.linkedin}
              </Link>
            </div>
            <div className="footer__content__title__iso">
              <Link
                href="https://www.bsigroup.com/en-IN/validate-bsi-issued-certificates/client-directory-profile/ADJ_UT-0047905479-000"
                target="_blank"
              >
                <Image src={isoLogo} height={40} width={44} />
              </Link>

              <Image
                src={registeredIcon}
                height={6}
                width={7.4}
                style={{ top: -34, position: 'relative' }}
                className="footer__content__title__iso_registered"
              />
              <div className="footer__content__title__iso__label">
                ISO 27001(ISMS)
              </div>
            </div>
          </div>
          <div className="footer__content__links">
            <Typography variant="body1" sx={{ fontWeight: '600' }}>
              {PAGE_FOOTER().LINK_HEADER}
            </Typography>{' '}
            {FOOTER_LINKS.map((item, index) => (
              <Link
                key={index}
                href={item.LINK}
                color="inherit"
                underline="none"
                target="_blank"
                rel="noreferrer"
              >
                <Typography
                  className="pointer"
                  variant="body2"
                  sx={{ fontWeight: '500' }}
                >
                  {item.NAME}
                </Typography>
              </Link>
            ))}
          </div>
          <div className="contact-us-section">
            <div className="contact-us-section__description">
              <Typography variant="body1" sx={{ fontWeight: '600' }}>
                {PAGE_FOOTER().COMMENT}
              </Typography>
              <Typography variant="body2">
                {PAGE_FOOTER().DESCRIPTION}
              </Typography>
            </div>
            <div className="contact-us-section__icons">
              {svgIcons.phone}
              <Typography
                variant="body2"
                className="contact-us-section__icons__content"
              >
                {PAGE_FOOTER().PHONE_NUMBER}
              </Typography>
            </div>
            <div className="contact-us-section__icons">
              {svgIcons.email}
              <Typography
                variant="body2"
                className="contact-us-section__icons__content"
              >
                {PAGE_FOOTER().EMAIL}
              </Typography>
            </div>
            <div className="contact-us-section__icons">
              {svgIcons.markerPin}
              <Typography
                variant="body2"
                className="contact-us-section__icons__content"
              >
                {PAGE_FOOTER().ADDRESS}
              </Typography>
            </div>
          </div>
        </div>
        <Divider className="divider"></Divider>
        <div className="footer__rights">
          <div className="footer__rights__reserved">
            <Typography variant="body2">{PAGE_FOOTER().RIGHTS}</Typography>
          </div>
          <div className="footer__rights__policy">
            <Link
              href="https://khatabook.com/terms"
              color="inherit"
              underline="none"
              target="_blank"
              rel="noreferrer"
            >
              <Typography
                variant="body2"
                className="footer__rights__policy__content"
              >
                {PAGE_FOOTER().TERMS_CONDITION}
              </Typography>
            </Link>
            <Link
              href="https://khatabook.com/privacy"
              color="inherit"
              underline="none"
              target="_blank"
              rel="noreferrer"
            >
              <Typography
                variant="body2"
                className="footer__rights__policy__content"
              >
                {PAGE_FOOTER().PRIVACY}
              </Typography>
            </Link>
            <Link
              href="https://khatabook.com/lending-terms"
              color="inherit"
              underline="none"
              target="_blank"
              rel="noreferrer"
            >
              <Typography
                variant="body2"
                className="footer__rights__policy__content"
              >
                {PAGE_FOOTER().LENDING_TERMS}
              </Typography>
            </Link>
            <Link
              href="https://khatabook.com/grievance-redressal-policy"
              color="inherit"
              underline="none"
              target="_blank"
              rel="noreferrer"
            >
              <Typography
                variant="body2"
                className="footer__rights__policy__content"
              >
                {PAGE_FOOTER().GRIEVANCES_REDRESSAL}
              </Typography>
            </Link>
            <Link
              href={`${process.env.NEXT_PUBLIC_BASE_URL}/dlg-disclosure`}
              color="inherit"
              underline="none"
              target="_blank"
              rel="noreferrer"
            >
              <Typography
                variant="body2"
                className="footer__rights__policy__content"
              >
                {PAGE_FOOTER().DLG_DISCLOSURE}
              </Typography>
            </Link>
          </div>
        </div>
      </div>
    </FooterWrapper>
  );
};

export default Footer;
