import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { Typography, useMediaQuery } from '@mui/material';
import theme from 'features/core/theme';
import Link from 'next/link';
const BlogCard = ({ blog, index }) => {
  const isMd = useMediaQuery(theme.breakpoints.down('md'));
  const isSm = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <BlogCardStyle theme={useTheme()} isMobile={isSm || isMd} index={index}>
      <img
        className="blogThumbnail"
        width="90%"
        height={150}
        src={blog.image}
        alt={blog.image_alt}
      />
      <div className="blogSummaryContent">
        <div className="category">
          <Typography variant="h6">{blog.category.name}</Typography>
        </div>
        <Typography className="title">{blog.title}</Typography>
        <div className="summaryContainer">
          <div className="summary">
            {`${blog.short_description.split(' ').splice(0, 20).join(' ')} ...`}
            <Link href={`https://khatabook.com/blog/${blog.slug}/`}>
              <span className="readMoreText">Read More</span>
            </Link>
          </div>
        </div>
      </div>
    </BlogCardStyle>
  );
};

const BlogCardStyle = styled('div')(
  ({
    theme,
    isMobile,
    index
  }: {
    theme: any;
    isMobile: boolean;
    index: number;
  }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: isMobile ? 'column' : 'row',
    float: 'left',
    height: 'content-fit',
    '.blogThumbnail': {
      alignSelf: 'center',
      borderRadius: '12px'
    },
    '.blogSummaryContent': {
      margin: `0rem ${!isMobile ? '1.5rem' : '0rem'}`,
      display: 'flex',
      float: 'left',
      flexDirection: 'column'
    },
    '.category': {
      marginTop: '.5rem',
      padding: '0.2rem .4rem',
      width: 'fit-content',
      borderRadius: '8px',
      color: `${theme.palette.commonBgColor.lowDarkBlue}`,
      backgroundColor:
        index % 2 == 0
          ? `${theme.palette.commonBgColor.brightBlue}`
          : `${theme.palette.commonBgColor.lightBlue2}`
    },
    '.title': {
      marginTop: '.5rem',
      fontWeight: '600',
      fontSize: '16px'
    },
    '.summary': {
      marginTop: '1rem',
      fontSize: '16px',
      lineHeight: '24px',
      color: `${theme.palette.textColors.blackishGreen}`
    },
    '.summaryContainer': {
      display: 'flex',
      float: 'inline-start',
      justifyContent: 'space-between',
      flexDirection: 'row'
    },
    '.readMoreText': {
      fontSize: '18px',
      fontWeight: '600',
      cursor: 'pointer',
      letterSpacing: '0.001px',
      color: `${theme.palette.textColors.lightBlue}`
    }
  })
);

export default BlogCard;
