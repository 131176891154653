import styled from '@emotion/styled';

export const BillingAccountingFeatureListStyled = styled('div')(
  ({ theme }: any) => ({
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
    textAlign: 'center',
    alignItems: 'flex-start',
    [theme.breakpoints.down('md')]: {
      textAlign: 'left',
      alignItems: 'flex-start',
      flexDirection: 'column'
    }
  })
);

export const BillingAccountFeatureItem = styled('div', {
  shouldForwardProp: prop => prop !== 'isLast' && prop !== 'borderMobileRight'
})(({ theme, isLast, borderMobileRight }: any) => ({
  paddingRight: '.5rem',
  marginRight: !isLast ? '1.5rem' : '0',
  fontSize: '0.9rem',
  color: theme.palette.textColors.grey,
  '.feature-text': {
    flexDirection: 'row',
    display: 'flex',
    alignItems: 'center',
    textAlign: 'start'
  },
  '.border-div': {
    width: 2,
    height: '1.5rem',
    background: !isLast ? theme.palette.error.main : undefined,
    marginLeft: '1rem'
  },
  [theme.breakpoints.down('md')]: {
    marginBottom: '0.5rem',
    width: '75%',
    paddingBottom: '1.5rem',
    borderBottom: !isLast
      ? `0.1rem dotted ${theme.palette.borderColor}`
      : undefined,
    borderRight: '0',
    ...{
      ...(borderMobileRight
        ? {
            borderRight: '0',
            borderBottom: 0
          }
        : {})
    },
    '.border-div': {
      display: 'none'
    },
    '.feature-text': {
      textAlign: 'start'
    }
  }
}));
