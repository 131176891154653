import Blog from 'components/Blog';
import { Dispatch, SetStateAction } from 'react';
import { BizAnalystBanner } from './biz-analyst-banner';
import GrowingBusiness from './growing-business';
import LandingPageHeader from './header';
import Portfolio from './portfolio-section';

interface MainContentProps {
  setPhoneNumber?: Dispatch<SetStateAction<string>>;
  handleLoginModal?: ({
    open,
    phoneNumber
  }: {
    open: boolean;
    phoneNumber?: string;
  }) => void;
  updateSourceToLoginPopup?: Dispatch<SetStateAction<string>>;
}

const MainContent = ({
  setPhoneNumber,
  handleLoginModal,
  updateSourceToLoginPopup
}: MainContentProps) => {
  return (
    <>
      <LandingPageHeader
        setPhoneNumber={setPhoneNumber}
        handleLoginModal={handleLoginModal}
        updateSourceToLoginPopup={updateSourceToLoginPopup}
      />
      <GrowingBusiness />
      <Portfolio />
      <BizAnalystBanner />
      <Blog fetch={true} />
    </>
  );
};

export default MainContent;
