import styled from '@emotion/styled';
import { Link, Typography } from '@mui/material';
import { svgIcons } from 'assets/svg-icons';
import { APP_DOWNLOAD_LINK_LIST_FOR_FOOTER } from './constant';

const MobileLandingPageHeader = () => {
  return (
    <MobileLandingPageHeaderStyle>
      {/* <div className="appsIcon">
        {APP_DOWNLOAD_LINK_LIST_FOR_FOOTER.map(({ image, link }, index) => (
          <div className="appImageBadge" key={index}>
            <Link
              key={index}
              href={link}
              color="inherit"
              underline="none"
              target="_blank"
              rel="noreferrer"
              className="app-link"
            >
              <div className="icon">{image}</div>
            </Link>
          </div>
        ))}
      </div> */}
      <div className="trial-pitch-desktop">
        <div style={{ alignSelf: 'start' }}>{svgIcons.deskTopIcon}</div>
        <div className="summaryContainer">
          <Typography variant="h4" className="title">
            Khatabook now available on Desktop
          </Typography>
          <Typography className="summary">
            Open{' '}
            <span>
              <Link
                href="https://khatabook.com/"
                target="_blank"
                underline="none"
                rel="noreferrer"
              >
                {' '}
                khatabook.com{' '}
              </Link>
            </span>
            on your laptop or desktop computer and log in using your registered
            phone number.
          </Typography>
        </div>
      </div>
    </MobileLandingPageHeaderStyle>
  );
};

const MobileLandingPageHeaderStyle = styled('div')(({ theme }: any) => ({
  display: 'flex',
  flexDirection: 'column',
  '.appsIcon': {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly'
  },
  '.icon': {
    marginRight: '2rem',
    width: '140px',
    height: '42px'
  },
  '.title': {
    fontSize: '14px',
    fontWeight: '600'
  },
  '.summary': {
    marginTop: '.5rem',
    fontSize: '14px'
  },
  span: {
    color: 'blue',
    fontWeight: '600'
  },
  '.summaryContainer': {
    marginLeft: '.5rem'
  }
}));

export default MobileLandingPageHeader;
