import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import React, { Fragment } from 'react';
import { Field, FieldRenderProps, Form } from 'react-final-form'; //TODO;

import { svgIcons } from 'assets/svg-icons';
import Button from 'atoms/button';
import {
  FeatureCheckWrapper,
  FieldWrapper,
  GrowingBusinesswrapper,
  HeaderBodyWrapper,
  HeaderCardWrapper,
  HeaderTitleSubsection
} from './styled';

import TextField from 'atoms/text-field';
import { INDIAN_PHONE_LENGTH, INDIA_COUNTRY_CODE } from 'constants/phone';
import { BillingAccountingFeatureList } from './billing-accounting-feature-list';
import { LandingPageServerImages, PAGE_HEADER } from './constant';
import { Box } from '@mui/material';
import MobileDesktopLandingPageHeader from './mobile-desktop-header-page';
import MobileLandingPageHeader from './mobile-page-header';
import landingPageIcon from 'assets/landing-page-icon';
import { isIOSDevice } from './helper';
import LiveDemoInfo from './common/demo-account/LiveDemoInfo';
import { DEMO_ACCOUNT } from './footer/constant';
import { checkForMobileDevice } from 'utils/browserUtils';

interface LandingPageProps {
  setPhoneNumber?: (p: string) => void;
  handleLoginModal?: ({ open: boolean, phoneNumber: string }) => void;
  updateSourceToLoginPopup?: (source: string) => void;
}

const LandingPageHeader = ({
  setPhoneNumber,
  handleLoginModal,
  updateSourceToLoginPopup
}: LandingPageProps) => {
  let urlData = [];
  let isMobileDesktoppUrl = false;
  if (typeof window !== 'undefined') {
    urlData = window.location.href.split('/');
  }
  const onSubmit = ({ phoneNumber }: { phoneNumber: string }) => {
    setPhoneNumber?.(phoneNumber);
    handleLoginModal?.({ open: true, phoneNumber });
    updateSourceToLoginPopup?.('getStarted');
  };

  const handleTextFieldInputChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    props: FieldRenderProps<any, HTMLElement, any>
  ) => {
    const nonNumReg = /[^0-9]/g;
    e.target.value = e.target.value.replace(nonNumReg, '');
    props.input.onChange(e); //final-form's onChange
  };
  const iosDeivce = isIOSDevice();
  const isMobile = checkForMobileDevice();
  if (urlData.length > 0 && urlData[urlData.length - 1] == 'desktop') {
    isMobileDesktoppUrl = true;
  }
  const headerTitle =
    isMobile == true && !isMobileDesktoppUrl
      ? 'Business hua easy!'
      : PAGE_HEADER.HEADER.SECTION_1;
  const headerSubTitel =
    isMobile == true && !isMobileDesktoppUrl
      ? 'Complete business management platform for MSMEs'
      : PAGE_HEADER.DESCRIPTION;
  return (
    <Fragment>
      <HeaderBodyWrapper>
        <div className="header-box">
          <div className="left-asset">{svgIcons.tricycle}</div>
          <div className="header-content">
            <div className="header-title-container">
              <Typography variant="h3" className="header-title">
                {headerTitle}
              </Typography>
              {(!isMobile || isMobileDesktoppUrl) && (
                <div className="elevated-container">
                  <Typography
                    variant="subtitle2"
                    className="elevated-container_arrow"
                  >
                    ^
                  </Typography>
                  <Typography variant="h3" className="elevated-container_text">
                    {PAGE_HEADER.HEADER.SECTION_2}
                  </Typography>
                </div>
              )}
              {(!isMobile || isMobileDesktoppUrl) && (
                <Typography
                  variant="h3"
                  className="header-title elevated-container_end_text"
                >
                  {PAGE_HEADER.HEADER.SECTION_3}
                </Typography>
              )}
            </div>
            <Typography className="header-subtitle">
              {headerSubTitel}
            </Typography>
            {isMobileDesktoppUrl && isMobile && (
              <MobileDesktopLandingPageHeader />
            )}
            {isMobile ? (
              <>
                <HeaderTitleSubsection>
                  <div className="tile">
                    <div className="subTextImg">
                      {svgIcons.createHeaderIcon}
                    </div>
                    <div className="subTextTitle">Create</div>
                    <div className="subText">
                      Customisable GST/Non-GST bills
                    </div>
                  </div>
                  <div className="tile">
                    <div className="subTextImg">{svgIcons.trackHeaderIcon}</div>
                    <div className="subTextTitle">Track</div>
                    <div className="subText">Sales, Purchase and Cashbook</div>
                  </div>
                  <div className="tile">
                    <div className="subTextImg">
                      {svgIcons.manageHeaderIcon}
                    </div>
                    <div className="subTextTitle">Manage</div>
                    <div className="subText">
                      Inventory & track profits reports
                    </div>
                  </div>
                </HeaderTitleSubsection>
              </>
            ) : null}
            <Form
              onSubmit={onSubmit}
              render={({ handleSubmit }) => (
                <form className="phone-number-form" onSubmit={handleSubmit}>
                  {isMobile ? (
                    <Typography
                      textAlign="center"
                      component="div"
                      className="mobile-sub-header-text"
                    >
                      Use Khatabook on Desktop
                    </Typography>
                  ) : null}
                  <FieldWrapper>
                    <Field className="phone-number" name="phoneNumber">
                      {props => {
                        return (
                          <TextField
                            className={props.className}
                            placeholder="Enter your phone number"
                            maxLength={INDIAN_PHONE_LENGTH}
                            size={isMobile ? 'small' : 'medium'}
                            {...props.input}
                            onChange={(
                              e: React.ChangeEvent<HTMLInputElement>
                            ) => handleTextFieldInputChange(e, props)}
                            startAdornment={
                              <div
                                style={{
                                  marginRight: '0.5rem',
                                  borderRight: '1px solid #D6D6D6',
                                  display: 'flex',
                                  alignItems: 'center',
                                  padding: 16
                                }}
                              >
                                <Typography style={{ fontSize: 18 }}>
                                  {INDIA_COUNTRY_CODE}
                                </Typography>
                              </div>
                            }
                          />
                        );
                      }}
                    </Field>
                  </FieldWrapper>

                  <Button
                    className="start-trial_btn"
                    variant="contained"
                    type="submit"
                    size={isMobile ? 'small' : 'medium'}
                  >
                    Get Started
                  </Button>
                </form>
              )}
            />
            {isMobile ? (
              <div className="video-section">
                <Typography
                  textAlign="center"
                  component="div"
                  className="mobile-sub-header-text"
                >
                  Built with features for growing businesses
                </Typography>
                <iframe
                  width="100%"
                  height="315"
                  src="https://www.youtube.com/embed/i2rhtamwnJc"
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowFullScreen
                />
              </div>
            ) : null}
            <FeatureCheckWrapper>
              {isMobile && !isMobileDesktoppUrl && <MobileLandingPageHeader />}
              <div className="feature-item">
                <Typography variant="h4" className="feature-item_heading">
                  Billing & Accounting for all businesses
                </Typography>
                <BillingAccountingFeatureList />
              </div>
            </FeatureCheckWrapper>
            <LiveDemoInfo
              title={DEMO_ACCOUNT().TITLE}
              description={DEMO_ACCOUNT().DESCRIPTION}
              ctaText={DEMO_ACCOUNT().CTA_TITLE}
            />
          </div>
        </div>

        <div className="advertisement_banner">
          <img
            src={LandingPageServerImages.TOP_RIGHT_PANE}
            alt="advetisement banner"
          ></img>
        </div>
      </HeaderBodyWrapper>
      <GrowingBusinesswrapper>
        <HeaderCardWrapper isMobile={isMobile}>
          <div className="businessess">{svgIcons.kbLogoWithName}</div>
          {!isMobile && (
            <div className="semiCircle">
              {landingPageIcon.pinkSemiCircleIcon}
            </div>
          )}
          <div className="card_column ">
            <Typography
              variant="h5"
              sx={{
                fontSize: '18px'
              }}
            >
              3 Cr+ Businesses using our free apps
            </Typography>
            <Typography variant="body2" className="card_column__subtitle">
              Access your account anywhere, anytime.
            </Typography>
            <div className="app_store">
              <div className="app_store_box">
                <Link
                  href={
                    iosDeivce
                      ? 'https://kb.sng.link/Bjhws/ihwb/x68n'
                      : 'https://play.google.com/store/apps/details?id=com.vaibhavkalpe.android.khatabook&hl=en_IN&gl=US'
                  }
                  color="inherit"
                  underline="none"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Box display="flex" alignItems="center">
                    {svgIcons.googlePlayIocn}
                    <Typography className="appNameText" variant="h5">
                      Play Store
                    </Typography>
                  </Box>
                </Link>
              </div>
              <div className="app_store_box">
                <Link
                  href="https://apps.apple.com/in/app/khata-book-udhar-bahi-khata/id1488204139"
                  color="inherit"
                  underline="none"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Box display="flex" alignItems="center">
                    {svgIcons.appleIcon}
                    <Typography className="appNameText" variant="h5">
                      App Store
                    </Typography>
                  </Box>
                </Link>
              </div>
            </div>
          </div>
        </HeaderCardWrapper>
        <HeaderCardWrapper
          isMobile={isMobile}
          lightBlue={false}
          textColor={true}
        >
          <div className="card_column">
            <Typography
              variant="h5"
              sx={{
                fontSize: '18px'
              }}
            >
              Already use Tally? *
            </Typography>
            <Typography className="card_column__bizanalyst" variant="body2">
              Get Tally * on mobile with BizAnalyst
            </Typography>
            <Link
              href="https://ba.sng.link/D71ns/i8tl"
              color="inherit"
              underline="none"
              target="_blank"
              rel="noreferrer"
            >
              <Typography
                className="goto-biz"
                variant="body2"
                sx={{
                  fontWeight: '700'
                }}
              >
                Go to BizAnalyst
                <div className="biz-arrow">{svgIcons.arrowTopRight}</div>
              </Typography>
            </Link>
          </div>
          {!isMobile && (
            <div className="semiCircleBlue">
              {landingPageIcon.blueSemiCirleIcon}
            </div>
          )}
          <div
            className="businessess"
            style={{ marginRight: '32px', alignSelf: 'center' }}
          >
            {svgIcons.baLogoWithName}
          </div>
        </HeaderCardWrapper>
      </GrowingBusinesswrapper>
    </Fragment>
  );
};

export default LandingPageHeader;
