import styled from '@emotion/styled';
import { textTransform } from '@mui/system';
import { svgIcons } from 'assets/svg-icons';
import { maxHeaderSize } from 'http';

export const LandingPageWrapper = styled('div')(({ theme }: any) => ({
  margin: '0 auto',
  width: '100%',
  textAlign: 'initial',
  overflow: 'scroll',
  overflowX: 'hidden',
  maxHeight: '100vh',

  [theme.breakpoints.up('xs')]: {
    h1: {
      fontSize: '2.25rem',
      fontWeight: '700'
    },
    h2: {
      fontSize: '1.875rem',
      fontWeight: '700'
    },
    h3: {
      fontSize: '1.25rem',
      fontWeight: '700'
    },
    h4: {
      fontSize: '1.125rem',
      fontWeight: '500'
    }
  },
  [theme.breakpoints.up('sm')]: {
    h1: {
      fontSize: '2.25rem',
      fontWeight: '700'
    },
    h2: {
      fontSize: '1.875rem',
      fontWeight: '700'
    },
    h3: {
      fontSize: '1.25rem',
      fontWeight: '700'
    },
    h4: {
      fontSize: '1.125rem',
      fontWeight: '500'
    }
  },
  [theme.breakpoints.up('md')]: {
    h1: {
      fontSize: '3rem',
      fontWeight: '700'
    },
    h2: {
      fontSize: '2.25rem',
      fontWeight: '700'
    },
    h3: {
      fontSize: '1.5rem',
      fontWeight: '700'
    },
    h4: {
      fontSize: '1.25rem',
      fontWeight: '500'
    }
  },
  [theme.breakpoints.up('lg')]: {
    h1: {
      fontSize: '3rem',
      fontWeight: '700'
    },
    h2: {
      fontSize: '2.25rem',
      fontWeight: '700'
    },
    h3: {
      fontSize: '1.5rem',
      fontWeight: '700'
    },
    h4: {
      fontSize: '1.25rem',
      fontWeight: '500'
    }
  },
  [theme.breakpoints.up('xl')]: {
    h1: {
      fontSize: '3.75rem',
      fontWeight: '700'
    },
    h2: {
      fontSize: '3rem',
      fontWeight: '700'
    },
    h3: {
      fontSize: '1.875rem',
      fontWeight: '700'
    },
    h4: {
      fontSize: '1.5rem',
      fontWeight: '500'
    }
  }
}));

export const HeaderBodyWrapper = styled('div')(({ theme }: any) => ({
  display: 'flex',
  backgroundColor: `${theme.palette.commonBgColor.lightPink}`,
  paddingLeft: '6.5rem',

  [theme.breakpoints.down('md')]: {
    display: 'block',
    padding: '1rem 1rem 3rem 1rem'
  },

  '.header-box': {
    position: 'relative',
    width: '50%',
    textAlign: 'initial',
    color: `black`,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    zIndex: 1,
    '.left-asset': {
      position: 'absolute',
      bottom: 0,
      left: '-20rem',
      zIndex: -1,
      [theme.breakpoints.down('md')]: {
        display: 'none'
      }
    },
    '.header-content': {
      maxWidth: '37rem',
      '.apps-section': {
        display: 'flex',
        justifyContent: 'space-between',
        color: `${theme.palette.commonBgColor.white}`,
        width: '21rem',
        img: {
          padding: '0.5rem 0',
          width: '10rem'
        },
        [theme.breakpoints.up('md')]: {
          display: 'none'
        }
      },
      '.header-title-container': {
        marginBottom: '0.2rem',
        display: 'flex',
        flexDirection: 'row',
        '.header-title': {
          fontSize: '44px',
          fontWeight: '700',
          letterSpacing: '-0.02rem',
          fontFamily: 'Poppins, cursive',
          [theme.breakpoints.down('md')]: {
            fontSize: '36px'
          }
        }
      },
      '.elevated-container': {
        display: 'flex',
        flexDirection: 'row',
        position: 'relative',
        margin: '0 0.2rem ',
        '&_arrow': {
          fontSize: '30px',
          fontWeight: 400
        },
        '&_text': {
          position: 'absolute',
          top: '-2.4rem',
          fontSize: '34px',
          minWidth: '8rem',
          fontFamily: 'Grand Hotel, cursive',
          fontStyle: 'normal',
          transform: 'rotate(-6.62deg)',
          fontWeight: 400,
          [theme.breakpoints.down('md')]: {
            left: '-1.5rem'
          }
        },
        '&_end_text': {}
      },
      '.header-subtitle': {
        fontSize: '37px',
        marginBottom: '2rem',
        [theme.breakpoints.down('md')]: {
          fontSize: '24px'
        }
      },
      [theme.breakpoints.down('md')]: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
      }
    },

    [theme.breakpoints.down('md')]: {
      width: '100%',
      textAlign: 'center',
      alignItems: 'center'
    }
  },
  '.advertisement_banner': {
    width: '45%',
    padding: '2rem 0rem 2rem 2rem',
    textAlign: 'right',
    img: {
      width: '160%',
      position: 'relative',
      right: '4rem',
      bottom: '-7rem',
      [theme.breakpoints.down('md')]: {
        width: '100%',
        right: '0',
        left: '10%'
      }
    },
    [theme.breakpoints.down('md')]: {
      width: 'auto',
      padding: 0
    }
  },
  '.phone-number-form': {
    display: 'flex',
    maxWidth: '30rem',
    marginBottom: '2rem',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      gap: theme.spacing(2),
      width: '100%',
      fontSize: '0.95rem',
      marginTop: theme.spacing(15)
    },
    'button.MuiButton-sizeSmall': {
      lineHeight: '2.4em'
    }
  },
  '.start-trial_btn': {
    backgroundColor: `${theme.palette.error.main}`,
    fontSize: '18px',
    fontWeight: 600,
    [theme.breakpoints.down('lg')]: {
      fontSize: '0.875rem'
    },
    [theme.breakpoints.down('md')]: {
      width: '50%',
      fontSize: '0.95rem',
      alignSelf: 'center',
      textTransform: 'uppercase'
    },
    ':hover': {
      backgroundColor: `${theme.palette.commonBgColor.darkRed}`
    }
  },
  '.mobile-sub-header-text': {
    fontSize: '1em',
    fontWeight: 'bold',
    marginBottom: theme.spacing(2)
  }
}));

export const FieldWrapper = styled('div')(({ theme }: any) => ({
  height: '100%',
  width: '8rem',
  display: 'flex',
  flex: '3',
  [theme.breakpoints.down('md')]: {
    width: '100%'
  },
  '.phone-number': {
    border: 'none',
    color: 'black',
    width: '96%',
    backgroundColor: `${theme.palette.commonBgColor.white}`,
    borderRadius: '0.50rem',
    'input.MuiInputBase-inputSizeSmall': {
      fontSize: '18px'
    }
  }
}));

export const FeatureCheckWrapper = styled('div')(({ theme }: any) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  padding: '1rem 0',
  '.mobileTrialSummaryHeader': {
    display: 'flex',
    flexDirection: 'row',
    backgroundImage: `linear-gradient(${theme.palette.commonBgColor.white},${theme.palette.commonBgColor.lightPink} )`
  },
  '.trial-pitch-desktop': {
    display: 'none',
    [theme.breakpoints.down('md')]: {
      display: 'flex',
      margin: '0rem 1.5rem',
      flexDirection: 'row',
      backgroundImage: `linear-gradient(${theme.palette.commonBgColor.white},${theme.palette.commonBgColor.lightPink} )`,
      padding: '12px 12px',
      borderRadius: '0.75rem',
      textAlign: 'left',
      wordBreak: 'break-word',
      alignItems: 'center'
    },
    '&_title': {
      fontWeight: 600,
      fontSize: 12,
      marginLeft: 12
    },
    '&_description': {
      fontSize: 12,
      marginLeft: 12
    }
  },
  '.feature-item': {
    backgroundColor: 'white',
    padding: '36px 0px 36px 36px',
    borderRadius: '0.75rem',
    marginTop: '1rem',
    backgroundImage: `linear-gradient(to right, ${theme.palette.commonBgColor.white},${theme.palette.commonBgColor.lightPink} )`,
    [theme.breakpoints.down('md')]: {
      backgroundImage: `linear-gradient(${theme.palette.commonBgColor.white},${theme.palette.commonBgColor.lightPink} )`,
      textAlign: 'left'
    },
    '&_heading': {
      fontSize: 24,
      fontWeight: 600,
      marginBottom: 24,
      fontFamily: 'Poppins, cursive'
    }
  },
  [theme.breakpoints.down('md')]: {
    padding: 0
  }
}));

export const GrowingBusinesswrapper = styled('div')(({ theme }: any) => ({
  position: 'relative',
  top: '-3rem',
  display: 'flex',
  justifyContent: 'space-between',
  margin: '0rem 10%',
  zIndex: 10,
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    alignItems: 'space-between',
    margin: '0rem 1rem'
  }
}));
export const HeaderCardWrapper = styled('div')(
  ({ theme, lightBlue, isMobile, textColor }: any) => ({
    display: 'flex',
    justifyContent: 'space-between',
    marginRight: '1.5rem',
    background: lightBlue
      ? `${theme.palette.commonBgColor.lightBlue}`
      : `${theme.palette.commonBgColor.white}`,
    color: theme.palette.textColors.darkBlack,
    flex: 1,
    borderRadius: '0.50rem',
    border: `0.20rem solid ${
      lightBlue
        ? theme.palette.commonBgColor.lightBlue
        : theme.palette.commonBgColor.solidWhite
    }`,

    '.businessess': {
      display: 'flex',
      alignSelf: 'center',
      backgroundImage: `url(${svgIcons.kbCircle})`,
      padding: '1rem 0px 1rem 2rem',
      img: {
        height: '3.1rem',
        width: '3.1rem'
      }
    },
    '.semiCircle': {
      marginLeft: '1rem',
      marginBottom: '-10px'
    },
    '.semiCircleBlue': {
      marginLeft: '6rem',
      marginBottom: '-10px'
    },
    '.card_column': {
      display: 'flex',
      alignSelf: 'center',
      margin: `${isMobile ? '5% 5%' : '0% 5%'}`,
      flexDirection: 'column',
      '.card_column__subtitle': {
        alignSelf: 'end',
        color: `${theme.palette.borderColor}`
      },
      '.card_column__bizanalyst': {
        color: `${theme.palette.borderColor}`
      },
      '.goto-biz': {
        color: theme.palette.commonBgColor.light3Blue,
        marginTop: '0.5rem',
        display: 'flex',
        alignItems: 'center'
      },
      '.biz-arrow': {
        path: {
          stroke: `${theme.palette.commonBgColor.light3Blue}`
        }
      }
    },
    '.appNameText': {
      marginLeft: '.5rem'
    },
    '.app_store': {
      display: 'flex',
      alignSelf: 'end',
      marginTop: '0.50rem',
      '&_box': {
        paddingLeft: '.5rem',
        paddingRight: '.25rem',
        display: 'flex',
        alignItems: 'center'
      }
    },
    [theme.breakpoints.down('md')]: {
      width: `calc(100% - ${theme.spacing(2)})`,
      marginBottom: '2rem'
    }
  })
);

export const HeaderTitleSubsection = styled('div')(({ theme }: any) => ({
  display: 'flex',
  justifyContent: 'center',
  width: '100%',
  margin: `0 ${theme.spacing(2)} ${theme.spacing(2)}`,
  borderRadius: '12px',
  background: 'linear-gradient(white, transparent)',

  '.tile': {
    alignSelf: 'center',
    minHeight: '100px',
    border: 'none',
    width: '30%',
    margin: `0 2px ${theme.spacing(2)}`,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    height: theme.spacing(18),

    '.subTextImg': {
      minHeight: '70px',
      display: 'flex'
    },
    svg: {
      minHeight: '70px',
      maxHeight: '90px',
      verticalAlign: 'top'
    }
  },

  '.subTextTitle': {
    color: '#DC343B',
    fontSize: '.8em'
  },

  '.subText': {
    fontSize: '.7em'
  }
}));
